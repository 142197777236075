import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Pager from "../components/pager"
import pagedPageTitle from "../utils/paged-page-title"
import BorderedPageTitle from "../components/bordered-page-title"
import Teaser from "../components/tbproduct/teaser"

const Template = ({ data }) => {
  const { product_category, products } = data
  const { pageInfo: { currentPage } } = products
  const title = pagedPageTitle(product_category.name ?? ``, currentPage)
  return (
    <Layout title={title} breadcrumbs={[{ label: title, href: null }]}>
      <BorderedPageTitle>{title}</BorderedPageTitle>
      <div className="flex flex-row flex-wrap justify-start items-start">
        {
          (products.nodes.length > 0) ?
            products.nodes.map((node) => (
              <Teaser key={node.path} product={node} />
            )) : `商品が見つかりません。`
        }
      </div>
      <Pager pageInfo={products.pageInfo} pathPrefix={product_category.path} />
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($machine_name: String!, $skip: Int!, $limit: Int!) {
    product_category: tbProductCategory(machine_name: { eq: $machine_name }) {
      name
      machine_name
      path
    }

    products: allTbProduct(
      filter: {product_categories: {elemMatch: {machine_name: {eq: $machine_name}}}}
      limit: $limit,
      skip: $skip,
      sort: {fields: [order_weight, cms_id], order: [ASC, DESC]}
    ) {
      nodes {
        ...TbProductTeaser
      }
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        itemCount
        totalCount
        hasPreviousPage
      }
    }
  }
`
